import React, { useState, useEffect } from 'react';
import { Table, Button } from 'antd';
import { fetchPriceDynamicsData } from '../../services/pricingService';
import DynamicPricingModal from './DynamicPricingModal';
import './PriceDynamics.css';

const PriceDynamics = () => {
  const [pricingData, setPricingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    loadPricingData();
  }, []);

  const loadPricingData = async () => {
    try {
      setLoading(true);
      const data = await fetchPriceDynamicsData();
      setPricingData(data.price_dynamics);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching pricing data:', error);
      setLoading(false);
    }
  };

  const handleOpenModal = (record) => {
    setSelectedProduct(record);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedProduct(null);
  };

  const columns = [
    { title: 'Product ID', dataIndex: 'product_id', key: 'product_id' },
    { title: 'Product Name', dataIndex: 'product_name', key: 'product_name' },
    { title: 'Adjusted Price (ARS)', dataIndex: 'adjusted_selling_price_ars', key: 'adjusted_selling_price_ars' },
    { title: 'Discount (%)', dataIndex: 'discount_percentage', key: 'discount_percentage' },
    { title: 'ROI (%)', dataIndex: 'roi_percentage', key: 'roi_percentage' },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button onClick={() => handleOpenModal(record)}>
          Update Pricing
        </Button>
      ),
    },
  ];

  return (
    <div className="price-dynamics">
      <h2>Price Dynamics</h2>
      <Table
        columns={columns}
        dataSource={pricingData}
        rowKey="product_id"
        loading={loading}
      />
      {selectedProduct && (
        <DynamicPricingModal
          visible={isModalVisible}
          onCancel={handleModalClose}
          productId={selectedProduct.product_id} // Pass productId to the modal
          onSubmit={loadPricingData} // Refresh data after update
          isNewEntry={!selectedProduct.adjusted_selling_price_ars} // Determine if it's a new entry
        />
      )}
    </div>
  );
};

export default PriceDynamics;
