// DynamicPricingModal.js
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, notification } from 'antd';
import { updateDynamicPricing, fetchProductPricingDetails } from '../../services/pricingService';

const DynamicPricingModal = ({ visible, onCancel, productId, isNewEntry, onSubmit }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // Fetch initial values when modal opens
  useEffect(() => {
    const fetchInitialValues = async () => {
      if (visible && productId) {
        setLoading(true);
        try {
          const data = await fetchProductPricingDetails(productId);
          console.log("Fetched initial values:", data); // Debugging
          form.setFieldsValue({
            purchase_price_usd: data.purchase_price_usd,
            bank_exchange_rate_usd_gbp: data.bank_exchange_rate_usd_gbp,
            discount_percentage: data.discount_percentage,
            original_price_ars: data.original_price_ars,
          });
        } catch (error) {
          console.error("Error fetching initial values:", error);
          notification.error({
            message: "Error",
            description: "Failed to load pricing details. Please try again.",
          });
        } finally {
          setLoading(false);
        }
      }
    };

    fetchInitialValues();
  }, [visible, productId, form]);

  // Handle form submission
  const handleFinish = async (values) => {
    console.log("Form values on submit:", values); // Debugging

    const priceData = {
      purchase_price_usd: parseFloat(values.purchase_price_usd),
      bank_exchange_rate_usd_gbp: parseFloat(values.bank_exchange_rate_usd_gbp),
      discount_percentage: parseFloat(values.discount_percentage || 0),
      original_price_ars: parseFloat(values.original_price_ars),
    };

    const requestData = {
      product_id: productId,
      ...priceData,
    };

    console.log("Request data:", requestData); // Debugging

    try {
      // Correctly pass productId and priceData separately
      await updateDynamicPricing(productId, priceData);
      notification.success({
        message: isNewEntry ? "Pricing Added" : "Pricing Updated",
        description: `Pricing ${isNewEntry ? 'added' : 'updated'} successfully.`,
      });
      form.resetFields();
      onSubmit(); // Refresh data
      onCancel(); // Close modal
    } catch (error) {
      console.error("Error updating pricing:", error);
      notification.error({
        message: "Error",
        description: "Failed to update pricing. Please try again.",
      });
    }
  };

  return (
    <Modal
      visible={visible}
      title={isNewEntry ? "Add Pricing" : "Update Pricing"}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>Cancel</Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
          {isNewEntry ? "Add Pricing" : "Update Pricing"}
        </Button>
      ]}
    >
      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
      >
        <Form.Item
          name="purchase_price_usd"
          label="Purchase Price (USD)"
          rules={[{ required: true, message: 'Please enter the purchase price in USD' }]}
          initialValue={0} // Default value for debugging
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="bank_exchange_rate_usd_gbp"
          label="Bank Exchange Rate USD/GBP"
          rules={[{ required: true, message: 'Please enter the bank exchange rate' }]}
          initialValue={1.0} // Default value for debugging
        >
          <Input type="number" step="0.0001" />
        </Form.Item>

        <Form.Item
          name="original_price_ars"
          label="Original Price (ARS)"
          rules={[{ required: true, message: 'Please enter the original price in ARS' }]}
          initialValue={0} // Default value for debugging
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="discount_percentage"
          label="Discount (%)"
          rules={[{ required: true, message: 'Please enter the discount percentage' }]}
          initialValue={0} // Default value for debugging
        >
          <Input type="number" step="0.01" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DynamicPricingModal;
