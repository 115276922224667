// src/components/dashboard/DashboardCard.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './DashboardCard.css';

const DashboardCard = ({ title, description, link }) => {
  const navigate = useNavigate();

  return (
    <div className="dashboard-card" onClick={() => navigate(link)}>
      <div className="dashboard-card-content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default DashboardCard;
