import { io } from 'socket.io-client';

// Base URL of the Socket.IO server
const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_SERVER_URL || 'http://localhost:5000';

// Namespace to connect to
const SOCKET_NAMESPACE = '/products';

// Initialize Socket.IO client with enhanced debugging
const socket = io(`${SOCKET_BASE_URL}${SOCKET_NAMESPACE}`, {
  transports: ['websocket'],           // Use WebSocket transport for a persistent connection
  reconnectionAttempts: 5,             // Max reconnection attempts before failing
  reconnectionDelay: 1000,             // Delay in ms between reconnection attempts
  secure: SOCKET_BASE_URL.startsWith('https'), // Set secure if using HTTPS
  timeout: 5000,                       // Set connection timeout to 5 seconds
  autoConnect: true                    // Auto-connect on instantiation
});

// Event listeners for debugging purposes
socket.on('connect', () => {
  console.log('Connected to Socket.IO server with ID:', socket.id);
});

socket.on('disconnect', (reason) => {
  console.log('Disconnected from Socket.IO server:', reason);
  if (reason === 'io server disconnect') {
    // Manually reconnect if server-side disconnect occurs
    socket.connect();
  }
});

socket.on('reconnect_attempt', (attempt) => {
  console.log(`Reconnection attempt #${attempt}`);
});

socket.on('reconnect_failed', () => {
  console.error('Reconnection failed after maximum attempts.');
});

socket.on('connect_error', (err) => {
  console.error('Connection error:', err.message);
});

socket.on('reconnect', (attemptNumber) => {
  console.log(`Reconnected successfully on attempt #${attemptNumber}`);
});

// Specific listener for product_update event
socket.on('product_update', (data) => {
  console.log('Received product update:', data);
});

// Generic event logger for all incoming events
socket.onAny((event, ...args) => {
  console.log(`Received event: ${event}`, args);
});

export default socket;
