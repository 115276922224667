import React, { useEffect, useState } from 'react';
import './ProductCard.css';

const ProductCard = ({ product }) => {
    const defaultImage = `${process.env.REACT_APP_S3_BASE_URL}/assets/products/default.jpg`;

    const [mainImage, setMainImage] = useState(defaultImage);
    const [availableImages, setAvailableImages] = useState([]);
    const [showFullDescription, setShowFullDescription] = useState(false);

    useEffect(() => {
        if (product && product.assets && product.assets.length > 0) {
            // Find the primary image
            const primaryAsset = product.assets.find((asset) => asset.is_primary);

            // Set primary image as main image or fall back to the first image
            const fallbackImage = product.assets[0]?.image_path || defaultImage;
            setMainImage(primaryAsset ? primaryAsset.image_path : fallbackImage);

            // Set the available images with primary image first if it exists
            const otherAssets = product.assets.filter((asset) => !asset.is_primary);
            setAvailableImages(primaryAsset ? [primaryAsset, ...otherAssets] : product.assets);
        } else {
            // No assets available, use default image
            setAvailableImages([]);
            setMainImage(defaultImage);
        }
    }, [product]);

    const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
    const whatsappMessage = `Hello, I'm interested in purchasing ${product?.name}.`;
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`;

    return (
        <div className="product-card">
            <div className="image-container">
                <img
                    src={mainImage}
                    alt={product?.name || 'Product Image'}
                    className="main-image"
                    onError={(e) => {
                        console.error(`Error loading main image: ${mainImage}`);
                        // Fallback to the first image or default image if the main image fails
                        const fallbackImage = availableImages[0]?.image_path || defaultImage;
                        e.target.src = fallbackImage;
                        setMainImage(fallbackImage);
                    }}
                />
            </div>
            {availableImages.length > 1 && (
                <div className="thumbnail-gallery">
                    {availableImages.map((image, index) => (
                        <img
                            key={index}
                            src={image.image_path}
                            alt={`${product?.name || 'Product'} - ${image.angle || 'Image'}`}
                            className="thumbnail-image"
                            onClick={() => setMainImage(image.image_path)}
                            onError={(e) => {
                                console.error(`Error loading thumbnail image: ${image.image_path}`);
                                e.target.src = defaultImage;
                            }}
                        />
                    ))}
                </div>
            )}
            <div className="product-details">
                <h3>{product?.name}</h3>
                {product?.price && product.price > 0 ? (
                    <p className="product-price">
                        ${product.price.toLocaleString('en-US')} ARS
                    </p>
                ) : (
                    <p className="product-price">Price not available</p>
                )}
                <p><strong>Condition:</strong> {product?.condition || 'N/A'}</p>
                <p><strong>Color:</strong> {product?.color || 'N/A'}</p>
                <div className="description-container">
                    <p className={`product-description ${showFullDescription ? 'expanded' : ''}`}>
                        {product?.description || 'No description available.'}
                    </p>
                    {product?.description && product.description.length > 100 && (
                        <span
                            onClick={() => setShowFullDescription(!showFullDescription)}
                            className="see-more"
                        >
                            {showFullDescription ? 'See less' : 'See more'}
                        </span>
                    )}
                </div>
                <p className="product-id">
                    <strong>ID:</strong> {product?.id || 'N/A'}
                </p>
                <a
                    href={whatsappLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="whatsapp-button"
                >
                    <img
                        src="/assets/whatsapp/ChatOnWhatsAppButton/WhatsAppButtonGreenSmall.svg"
                        alt="Chat on WhatsApp"
                        className="whatsapp-icon"
                    />
                </a>
            </div>
        </div>
    );
};

export default ProductCard;
