import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import './LoginForm.css';

const LoginForm = () => {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setAuth, isAuthenticated } = useAuth();

  // Get API base URL from environment variables
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      console.log("Attempting login with identifier:", identifier); // Log attempt
      const response = await axios.post(`${API_BASE_URL}/auth/login`, {
        identifier,
        password,
      });

      console.log("Full login request response:", response); // Log full response for debugging
      console.log("Login response data:", response.data); // Log response.data specifically

      // Check if the login was successful based on the presence of access_token, refresh_token, and user
      if (response.data.access_token && response.data.refresh_token && response.data.user) {
        console.log("Login successful:", response.data.user);

        // Set authenticated status with the actual tokens and user data
        setAuth(response.data.user, response.data.access_token, response.data.refresh_token);
      } else {
        console.error("Login failed - missing access_token, refresh_token, or user data:", response.data);
      }
    } catch (error) {
      if (error.response) {
        console.error("Login error - server responded with:", error.response.data); // Log server response error
      } else {
        console.error("Login error - network or other issue:", error.message); // Log any other error
      }
    }
  };

  // Use effect to watch `isAuthenticated` and redirect if true
  useEffect(() => {
    if (isAuthenticated) {
      console.log("User authenticated, navigating to /dashboard");
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="login-container">
      <img src="/assets/images/company/project_david_logo.png" alt="Company Logo" className="login-logo" />
      <h2>Welcome to Pancho</h2>
      <form onSubmit={handleLogin} className="login-form">
        <input
          type="text"
          id="identifier"
          placeholder="Email address or Username"
          value={identifier}
          onChange={(e) => setIdentifier(e.target.value)}
          className="login-input"
        />
        <input
          type="password"
          id="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="login-input"
        />
        <button type="submit" className="login-button">Continue</button>
      </form>
      <div>
        <p>Don't have an account? <a href="/register">Sign up</a></p>
      </div>
    </div>
  );
};

export default LoginForm;
