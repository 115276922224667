// ProductModal.jsx
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Spin, notification, Upload, Button, Tooltip } from 'antd';
import { UploadOutlined, StarOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { fetchProductTypes, fetchProductTypeSchema } from '../../services/productService';
import './ProductModal.css';

const ProductModal = ({
  visible,
  onOk,
  onCancel,
  initialValues,
  isEditing,
  categoryOptions = [],
  loading,
  onDeleteImage,
}) => {
  const [form] = Form.useForm();
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [productTypeSchemas, setProductTypeSchemas] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [selectedSchema, setSelectedSchema] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [primaryImageIndex, setPrimaryImageIndex] = useState(null);

  useEffect(() => {
    const loadProductData = async () => {
      try {
        const [types, schemas] = await Promise.all([
          fetchProductTypes(),
          fetchProductTypeSchema(),
        ]);
        setProductTypeOptions(types.map((type) => ({ value: type.id, label: type.name })));
        setProductTypeSchemas(schemas);
      } catch (error) {
        console.error('Error fetching product types or schemas:', error);
      }
    };
    loadProductData();
  }, []);

  useEffect(() => {
    if (visible && initialValues && !loading) {
      setFormValuesAndFiles(initialValues);
    } else if (!visible) {
      resetForm();
    }
  }, [visible, initialValues, loading]);

  const setFormValuesAndFiles = (values) => {
    const productType = productTypeOptions.find((type) => type.label === values.product_type);
    const productTypeId = productType ? productType.value : null;
    setSelectedProductType(productTypeId);

    const initialFormValues = { ...values, product_type_id: productTypeId };

    if (values.attributes) {
      Object.keys(values.attributes).forEach((key) => {
        initialFormValues[key] = values.attributes[key];
      });
    }

    if (values.categories && values.categories.length > 0) {
      initialFormValues.categories = values.categories.map((cat) => cat.id);
    }

    if (values.condition) {
      initialFormValues.condition = values.condition;
    }

    form.setFieldsValue(initialFormValues);

    if (values.assets) {
      const initialFiles = values.assets.map((asset, index) => ({
        uid: asset.id,
        name: asset.image_path.split('/').pop(),
        status: 'done',
        url: asset.image_path,
        isPrimary: asset.is_primary,
        index,
      }));
      setFileList(initialFiles);
      const primaryIndex = initialFiles.findIndex((file) => file.isPrimary);
      setPrimaryImageIndex(primaryIndex !== -1 ? primaryIndex : null);
    }
  };

  const resetForm = () => {
    form.resetFields();
    setSelectedProductType(null);
    setSelectedSchema(null);
    setFileList([]);
    setPrimaryImageIndex(null);
  };

  const handleImageUpload = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleDeleteImage = async (file) => {
    try {
      if (file.uid && file.url) {
        await onDeleteImage(file.uid);
        setFileList((prevFileList) => prevFileList.filter((item) => item.uid !== file.uid));
      }
    } catch (error) {
      notification.error({ message: 'Failed to delete image' });
    }
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      values.product_type_id = selectedProductType;

      const dynamicAttributes = {};
      if (selectedSchema && Array.isArray(selectedSchema.attributes)) {
        selectedSchema.attributes.forEach((attr) => {
          dynamicAttributes[attr.attribute_name] = values[attr.attribute_name];
          delete values[attr.attribute_name];
        });
      }
      values.attributes = dynamicAttributes;

      const files = fileList.filter((file) => !file.url).map((file) => file.originFileObj);
      const imageOrder = fileList.reduce((orderMap, file, index) => {
        orderMap[index] = index;
        return orderMap;
      }, {});

      await onOk(values, files, primaryImageIndex, imageOrder);
      onCancel(); // Close the modal after successful submission
    } catch (error) {
      console.error('Error submitting form:', error);
      notification.error({ message: 'Error', description: 'Failed to submit the product form.' });
    }
  };

  const setPrimaryImage = (index) => {
    setPrimaryImageIndex(index);
  };

  return (
    <Modal
      className="product-modal"
      title={isEditing ? 'Edit Product' : 'Add Product'}
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      okText="Save"
      cancelText="Cancel"
    >
      {loading ? (
        <Spin tip="Loading..." />
      ) : (
        <Form form={form} layout="vertical" name="productForm">
          <Form.Item
            name="name"
            label="Product Name"
            rules={[{ required: true, message: 'Please enter the product name' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="description" label="Description">
            <Input.TextArea autoSize={{ minRows: 2 }} />
          </Form.Item>

          <Form.Item name="price_tracking_url" label="Price Tracking URL">
            <Input />
          </Form.Item>

          <Form.Item
            name="categories"
            label="Categories"
            rules={[{ required: true, message: 'Please select at least one category' }]}
          >
            <Select options={categoryOptions} placeholder="Select categories" mode="multiple" allowClear />
          </Form.Item>

          <Form.Item name="is_active" label="Active Status">
            <Select
              options={[
                { value: true, label: 'Active' },
                { value: false, label: 'Inactive' },
              ]}
            />
          </Form.Item>

          <Form.Item name="is_featured" label="Featured Status">
            <Select
              options={[
                { value: true, label: 'Featured' },
                { value: false, label: 'Not Featured' },
              ]}
            />
          </Form.Item>

          <Form.Item name="product_type_id" label="Product Type" rules={[{ required: true, message: 'Please select a product type' }]}>
            <Select options={productTypeOptions} placeholder="Select a product type" onChange={setSelectedProductType} />
          </Form.Item>

          {selectedSchema && selectedSchema.attributes?.map((attr) => (
            <Form.Item key={attr.attribute_name} name={attr.attribute_name} label={attr.label || attr.attribute_name} rules={[{ required: true, message: `Please enter ${attr.label || attr.attribute_name}` }]}>
              <Input />
            </Form.Item>
          ))}

          <Form.Item name="condition" label="Condition">
            <Select
              options={[
                { value: 'NEW', label: 'New' },
                { value: 'USED_LIKE_NEW', label: 'Used - Like New' },
                { value: 'USED_GOOD', label: 'Used - Good' },
                { value: 'REFURBISHED', label: 'Refurbished' },
              ]}
            />
          </Form.Item>

          <Form.Item name="color" label="Color">
            <Input />
          </Form.Item>

          <Form.Item label="Product Images">
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <Upload
                accept="image/*"
                multiple
                fileList={fileList}
                onChange={handleImageUpload}
                beforeUpload={() => false}
                listType="picture-card"
              >
                {fileList.length < 8 && <UploadOutlined />}
              </Upload>
              <Tooltip title="Images will be displayed in the order uploaded.">
                <InfoCircleOutlined style={{ marginLeft: '10px', color: '#8c8c8c' }} />
              </Tooltip>
            </div>
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
              {fileList.map((file, index) => (
                <div key={file.uid} style={{ position: 'relative' }}>
                  <Button
                    type={index === primaryImageIndex ? 'primary' : 'default'}
                    onClick={() => setPrimaryImage(index)}
                    icon={<StarOutlined />}
                    style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}
                  />
                  <Button
                    type="danger"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteImage(file)}
                    style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}
                  />
                  <img
                    src={file.url || file.thumbUrl}
                    alt="product"
                    style={{ width: 100, height: 100, objectFit: 'cover', border: index === primaryImageIndex ? '2px solid gold' : '' }}
                  />
                </div>
              ))}
            </div>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default ProductModal;
