import React from 'react';
import './Banner.css'; // Assuming you have custom styles for the banner

const Banner = () => {
  return (
    <div className="banner">
      <img src="/assets/banners/lead-banner.jpg" alt="Lead Banner" className="banner-image" /> {/* Adjusted path */}
    </div>
  );
};

export default Banner;

