import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const AuthContext = createContext(null);
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [guestToken, setGuestToken] = useState(null);

  const setAuth = (userData, accessTokenValue, refreshTokenValue) => {
    console.log("Setting authentication data...");
    console.log("Received access token:", accessTokenValue);
    console.log("Received refresh token:", refreshTokenValue);

    setUser(userData);
    setIsAuthenticated(true);
    setAccessToken(accessTokenValue);
    setRefreshToken(refreshTokenValue);

    localStorage.setItem('accessToken', accessTokenValue);
    localStorage.setItem('refreshToken', refreshTokenValue);
  };

  const logout = useCallback(() => {
    setUser(null);
    setIsAuthenticated(false);
    setAccessToken(null);
    setRefreshToken(null);
    setGuestToken(null);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('guestToken');
  }, []);

  const getGuestToken = useCallback(async () => {
    try {
      console.log("Attempting to retrieve guest token...");
      const response = await axios.get(`${apiBaseUrl}/auth/guest-token`);
      const newGuestToken = response.data.access_token;
      console.log("Guest token retrieved:", newGuestToken);
      setGuestToken(newGuestToken);
      localStorage.setItem('guestToken', newGuestToken);
      return newGuestToken;
    } catch (error) {
      console.error("Failed to retrieve guest token:", error);
      throw error;
    }
  }, []);

  const refreshAccessToken = useCallback(async () => {
    try {
      console.log("Attempting to refresh access token...");
      const response = await axios.post(`${apiBaseUrl}/auth/refresh_token`, {}, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      });
      const newAccessToken = response.data.access_token;
      console.log("New access token:", newAccessToken);
      setAccessToken(newAccessToken);
      localStorage.setItem('accessToken', newAccessToken);
      return newAccessToken;
    } catch (error) {
      console.error("Failed to refresh token:", error);
      logout();
      throw error;
    }
  }, [refreshToken, logout]);

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      async (config) => {
        const token = accessToken || guestToken;
        if (token) {
          console.log("Attaching token to request:", token);
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const newAccessToken = await refreshAccessToken();
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            return axios(originalRequest);
          } catch (err) {
            logout();
            return Promise.reject(err);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [accessToken, guestToken, refreshAccessToken, logout]);

  useEffect(() => {
    const initializeAuth = async () => {
      console.log("Initializing auth...");
      const savedAccessToken = localStorage.getItem('accessToken');
      const savedRefreshToken = localStorage.getItem('refreshToken');
      const savedGuestToken = localStorage.getItem('guestToken');

      if (savedAccessToken && savedRefreshToken) {
        setAccessToken(savedAccessToken);
        setRefreshToken(savedRefreshToken);
        try {
          const response = await axios.get(`${apiBaseUrl}/auth/user`, {
            headers: { Authorization: `Bearer ${savedAccessToken}` },
          });
          setUser(response.data.user);
          setIsAuthenticated(true);
        } catch (error) {
          if (error.response?.status === 401) {
            try {
              const newAccessToken = await refreshAccessToken();
              const response = await axios.get(`${apiBaseUrl}/auth/user`, {
                headers: { Authorization: `Bearer ${newAccessToken}` },
              });
              setUser(response.data.user);
              setIsAuthenticated(true);
            } catch (err) {
              logout();
            }
          } else {
            logout();
          }
        }
      } else if (savedGuestToken) {
        setGuestToken(savedGuestToken);
      } else {
        await getGuestToken();
      }
      setLoading(false);
    };

    initializeAuth();
  }, [refreshAccessToken, logout, apiBaseUrl, getGuestToken]);

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, loading, setAuth, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
export { AuthContext };
