import React from 'react';
import DashboardCard from './DashboardCard';
import './Dashboard.css';

const Dashboard = () => {
  console.log("Rendering Dashboard"); // Debugging output

  const cards = [
    { title: 'Product Management', description: 'Add or edit products in the catalog.', link: '/admin/product-management' },
    { title: 'Sales Reports', description: 'View detailed sales reports.', link: '/sales-reports' },
    { title: 'Finance', description: 'Manage financial records and reports.', link: '/finance' },
    { title: 'User Management', description: 'Manage users and permissions.', link: '/user-management' },
    // New card for Pricing Dynamics
    { title: 'Pricing Dynamics', description: 'Manage dynamic pricing and adjustments.', link: '/admin/pricing-dynamics' },
  ];

  return (
    <div className="dashboard">
      <h1>Dashboard</h1>
      <div className="dashboard-grid">
        {cards.map((card, index) => (
          <DashboardCard
            key={index}
            title={card.title}
            description={card.description}
            link={card.link}
          />
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
