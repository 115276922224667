import React from 'react';
import './App.css';
import ProductList from './components/product/ProductList';
import Dashboard from './components/admin/Dashboard';
import LoginForm from './components/login/LoginForm';
import ProductManagement from './components/admin/ProductManagement';
import PriceDynamics from './components/admin/PriceDynamics'; // Import PriceDynamics component
import ProtectedRoute from './components/protect/ProtectedRoute';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <Router>
          <main>
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<ProductList />} />
              <Route path="/login" element={<LoginForm />} />

              {/* Protected Routes */}
              <Route element={<ProtectedRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/admin/product-management" element={<ProductManagement />} />
                <Route path="/admin/pricing-dynamics" element={<PriceDynamics />} /> {/* New route for Pricing Dynamics */}
                {/* Add more protected routes here as needed */}
              </Route>

              {/* Fallback route for non-matching paths */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </main>
        </Router>
      </div>
    </AuthProvider>
  );
}

export default App;
