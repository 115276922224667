import React, { useEffect, useState, useCallback } from 'react';
import { fetchStorefrontProducts } from '../../services/productService';
import ProductCard from './ProductCard';
import Banner from '../layout/Banner/Banner';
import './ProductList.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const loadProducts = async () => {
    try {
      setLoading(true);
      const productData = await fetchStorefrontProducts({ page });

      // Ensure no duplicate products by checking the ID
      setProducts((prevProducts) => {
        const existingIds = new Set(prevProducts.map((product) => product.id));
        const uniqueProducts = productData.products.filter(
          (product) => !existingIds.has(product.id)
        );
        return [...prevProducts, ...uniqueProducts];
      });

      setTotalPages(productData.total_pages);
    } catch (error) {
      console.error('Error loading products:', error);
      toast.error('Failed to load products.');
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 &&
      !loading &&
      page < totalPages
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [loading, page, totalPages]);

  useEffect(() => {
    loadProducts();
  }, [page]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const handleNextPage = () => {
    if (page < totalPages && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1 && !loading) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const generatePageNumbers = () => {
    const pages = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) pages.push(i);
    } else {
      if (page > 3) pages.push(1, '...');
      const startPage = Math.max(2, page - 1);
      const endPage = Math.min(totalPages - 1, page + 1);
      for (let i = startPage; i <= endPage; i++) pages.push(i);
      if (page < totalPages - 2) pages.push('...', totalPages);
    }
    return pages;
  };

  return (
    <div>
      <Banner />
      <div className="product-list">
        {products.length > 0 ? (
          products.map((product) => <ProductCard key={product.id} product={product} />)
        ) : (
          <div>No products available.</div>
        )}
        {loading && <div>Loading more products...</div>}
      </div>
      <div className="pagination-controls">
        <button
          disabled={page <= 1 || loading}
          onClick={handlePreviousPage}
          className="pagination-button"
        >
          &lt; Previous
        </button>
        {generatePageNumbers().map((p, index) =>
          typeof p === 'number' ? (
            <button
              key={index}
              className={`pagination-page ${p === page ? 'active' : ''}`}
              onClick={() => setPage(p)}
            >
              {p}
            </button>
          ) : (
            <span key={index} className="pagination-ellipsis">
              ...
            </span>
          )
        )}
        <button
          disabled={page >= totalPages || loading}
          onClick={handleNextPage}
          className="pagination-button"
        >
          Next &gt;
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ProductList;
